import React, { useState, useRef } from "react";
import ReactPlayer from "react-player";
import Thumbnail from "./../../../public/home/thumbnail.webp";
import Dots from "./../../../public/home/dots.webp";
import { hostEventUrl, mediaUrl } from "../../config/config";

const NewHomeHeader = () => {
  const [playing, setPlaying] = useState(false);

  const videoRef = useRef(false);
  return (
    <>
      <div className="col col-12 d-flex flex-row home-header-container px-5 px-mob pt-6 g-0">
        <div className="col-lg-6 col-md-5 col pb-md-3 ms-lg-4 ms-md-4 ms-0  px-md-3 py-3 px-3 header-title-container">
          <h1 className="header-title mob-header-title d-inline-block ">
            Make Your Events <br />
            <span className="theme">Engaging</span> &{" "}
            <span className="theme">Effective</span>!
            <div className="dots-1"></div>
          </h1>
          <div className="mx-md-5 mt-4 desktop-display px-md-3">
            <ReactPlayer
              ref={videoRef}
              url={`${mediaUrl}/kh-product-demo.mp4`}
              controls
              width="100%"
              height="100%"
              style={{
                background: "#572148",
                border: "5px solid #FFFFFF",
                boxShadow: "4px 4px 30px rgba(0, 0, 0, 0.25)",
                borderRadius: "5px",
                padding: "15px",
                display: "flex",
              }}
              pip
              light={
                <img
                  src={Thumbnail}
                  className="w-100 h-100"
                  alt="Konfhub Event management platform features"
                />
              }
              playing={playing}
              playIcon={<></>}
              onEnded={() => {
                videoRef.current.showPreview();
                setPlaying(false);
              }}
              onClickPreview={() => {
                setPlaying(true);
              }}
              onStart={() => {
                setPlaying(true);
              }}
              onPause={() => {
                setPlaying(false);
              }}
              onPlay={(e) => {
                setPlaying(true);
              }}
            />
          </div>
          <div className="sub-text pt-4 pb-4">
            One-stop ticketing & event management platform for online, hybrid
            and in-person events. We help you drive the right audience & make
            your event more successful.
          </div>
          <div className="py-3 mobbtn-none">
            <a
              href="https://calendly.com/konfhub/konfhub"
              target="_blank"
              rel="noreferrer"
            >
              <button className="btn btn-header mb-lg-2 mb-2 mb-sm-0 btn-demo">
                Schedule Demo&nbsp;
                <i className="fas fa-chevron-circle-right arrow"></i>
              </button>
            </a>
            <a
              href={`${hostEventUrl}/create/event`}
              target="_blank"
              rel="noreferrer"
            >
              <button className="btn btn-header mb-lg-2 ms-0 ms-lg-5 ms-sm-3">
                Host an Event&nbsp;
                <i className="fas fa-chevron-circle-right arrow"></i>
              </button>
            </a>
          </div>
          <div className="py-3 desk-none">
            <a
              href="https://calendly.com/konfhub/konfhub"
              target="_blank"
              rel="noreferrer"
            >
              <button className="btn btn-header btn-new mb-lg-2 w-100 mb-2 mb-sm-0 btn-demo">
                Schedule Demo&nbsp;
                <i className="fas fa-chevron-circle-right arrow"></i>
              </button>
            </a>
            <a
              href={`${hostEventUrl}/create/event`}
              target="_blank"
              rel="noreferrer"
            >
              <button className="btn btn-header btn-new mb-lg-2 w-100 ms-0 ms-lg-5 ms-sm-3">
                Host an Event&nbsp;
                <i className="fas fa-chevron-circle-right arrow"></i>
              </button>
            </a>
          </div>
        </div>
        <div
          className="col-lg-6 col-md-6 mob-none d-flex justify-content-center flex-column col py-md-3 px-md-3 ps-lg-3 py-3m desktop-display2"
        >
          <div className="mx-md-5 px-md-3">
            <ReactPlayer
              ref={videoRef}
              url={`${mediaUrl}/kh-product-demo.mp4`}
              controls
              width="100%"
              height="100%"
              style={{
                background: "#572148",
                border: "5px solid #FFFFFF",
                boxShadow: "4px 4px 30px rgba(0, 0, 0, 0.25)",
                borderRadius: "5px",
                padding: "15px",
                display: "flex",
              }}
              pip
              light={
                <img
                  src={Thumbnail}
                  alt="KonfHub Demo"
                  className="w-100 h-100"
                />
              }
              playing={playing}
              playIcon={<></>}
              onEnded={() => {
                videoRef.current.showPreview();
                setPlaying(false);
              }}
              onClickPreview={() => {
                setPlaying(true);
              }}
              onStart={() => {
                setPlaying(true);
              }}
              onPause={() => {
                setPlaying(false);
              }}
              onPlay={(e) => {
                setPlaying(true);
              }}
            />
          </div>
        </div>
      </div>
      <style>{`
.home-header-container {
    background-color: #F6F9FC;
    color: #572148;
    padding-bottom:50px;
    padding-top: 40px;
    justify-content: space-between;
}
.desktop-display{
    display:none !important;
}

.home-header-container .header-title-container {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    justify-content: center !important;

}

.home-header-container .header-title {
    background: url(${Dots}) 1em em norepeat, url(${Dots}) 70% 40% norepeat;
    font-family: "Prompt";
    font-style: normal;
    font-weight: 700;
    font-size: 42px;
    line-height: 47px;
    position: relative;
}
.header-title{
    font-family: 'Poppins' !important;
}

.home-header-container .header-title .dots-1 {
    background: url(${Dots}) no-repeat;
    width: 130px;
    height: 96px;
    position: absolute;
    top: -8px;
    left: -30px;
    background-size: cover;
}

.home-header-container .header-title .dots-2 {
    background: url(${Dots}) no-repeat;
    width: 130px;
    height: 96px;
    position: absolute;
    bottom: -16px;
    right: 50px;
    background-size: cover;
}

.home-header-container .header-title .theme {
    color: #FB5850;
}
.desktop-none{
  diplay: none !important;
  position: relative;
}

.home-header-container .sub-text-title {
    font-family: 'Nunito';
    font-weight: 700;
    font-size: 28px;
    line-height: 32px;
    color: #572148;
}

.home-header-container .sub-text {
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 500;
    font-size: 19px;
    line-height: 140%;
    color: #525252;
    text-align: justify;
}

.home-header-container .video-wrapper {
    background: #572148;
    border: 5px solid #FFFFFF;
    box-shadow: 4px 4px 30px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
}

.home-header-container .stars i {
    margin: 0 5px;
}

.home-header-container .star-title {
    font-family: 'Nunito';
    color: #572148;
}
.btn-new{
  display: flex;
  justify-content: space-between;
}
.btn-new i{
  margin-top: 4px;
}
a{
    text-decoration: none;
}
.desk-none{
  display: none;
}
@media only screen and (min-width: 1330px) and (max-width: 1400px){
  .home-header-container .header-title {
    background: url(${Dots}) 1em em norepeat, url(${Dots}) 70% 40% norepeat;
    font-family: "Prompt";
    font-style: normal;
    font-weight: 700;
    font-size: 42px;
    line-height: 47px;
    position: relative;
}
.home-header-container .sub-text {
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 110%;
    color: #525252;
    text-align: justify;
}

}
@media only screen and (min-width: 1000px) and (max-width: 1330px){
  .home-header-container .header-title {
    background: url(${Dots}) 1em em norepeat, url(${Dots}) 70% 40% norepeat;
    font-family: "Prompt";
    font-style: normal;
    font-weight: 700;
    font-size: 42px;
    line-height: 47px;
    position: relative;
    text-align: center;
}
.home-header-container .sub-text {
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 500;
    font-size: 19px;
    line-height: 110%;
    color: #525252;
    text-align: justify;
}
.home-header-container .header-title-container {
    width: 100%;
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center !important;
    }
.desktop-display{
    display:block !important;
    z-index:10;
    }
.desktop-display2{
  display:none !important;
    z-index:10;

}
}


/* LG screens */
@media only screen and (max-width: 1000px) {
    .home-header-container {
      flex-direction: column !important;
  }
  .home-header-container .header-title {
    font-size: 42px !important;
    line-height: 47px !important;
  }
  .home-header-container .header-title-container {
    align-items: center;

}
.home-header-container .header-title-container {
    width: 100%;
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center !important;
    }
  .mob-none{
    display: none !important;
  }
  .home-header-container .header-title .dots-2 {
    background: url(${Dots}) no-repeat;
    width: 130px;
    height: 96px;
    position: absolute;
    bottom: -96px;
    right: 0px;
    background-size: cover;
}
  .px-mob{
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }
  .home-header-container .sub-text {
    font-size: 16px !important;
  }
  .mob-header-title{
    text-align: center !important;
  }
}

/* SM screens */
@media only screen and (min-width: 480px) and (max-width: 991px) {
    .home-header-container .header-title {
        font-size: 42px;
        line-height: 47px;
    }
    .home-header-container .header-title-container {
    align-items: center;

}

    .home-header-container .sub-text {
        font-size: 16px;
        line-height: 24px
    }
    .desktop-display{
    display:block !important;
    z-index:10
    }
}

/* XS screens */
@media only screen and (max-width: 480px) {
    .home-header-container .header-title {
        font-size: 42px !important;
        line-height: 47px !important;
    }
    .home-header-container .header-title-container {
    align-items: center;

}
    .mobbtn-none{
      display: none;
    }

    .desk-none{
      display: block;
    }

    .home-header-container .sub-text {
        font-size: 16px;
        line-height: 24px
    }
    .desktop-display{
    display:block !important;
    z-index:10;
    }
}
`}</style>
    </>
  );
};

export default NewHomeHeader;
